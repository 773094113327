/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LinkedItem } from '../models/LinkedItem';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { requestHdr as __requestHdr } from '../core/request';

export class LinkedItemsService {

    /**
     * @param pagingHeader 
     * @param filter 
     * @returns Terminal Success
     * @throws ApiError
     */
    public static async get(token: string,
        parentId: number,
        parentType: string,
        linkedEntityType: string
    ): Promise<Array<number>> {
        return await __request(OpenAPI, {
            method: 'GET',
            url: `/api/LinkedItems`,
            headers: {                
                Authorization: `Bearer ${token}`
            },
            query: {
                'parentId': parentId,
                'parentType': parentType,
                'linkedEntityType': linkedEntityType
            }
        });
    }

    /**
     * @param requestBody 
     * @returns Terminal Success
     * @throws ApiError
     */
    public static async post(token: string,
        requestBody?: LinkedItem,
    ) {
        return await __request(OpenAPI, {
            method: 'POST',
            url: `/api/LinkedItems`,
            body: [requestBody],
            headers: { Authorization: `Bearer ${token}` }
        });
    }

    public static async postArray(token: string,
        requestBody?: LinkedItem[],
    ) {
        return await __request(OpenAPI, {
            method: 'POST',
            url: `/api/LinkedItems`,
            body: requestBody,
            headers: { Authorization: `Bearer ${token}` }
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static async delete(token: string,
        parentId: number,
        parentType: string,
        linkedEntityId: number,
        linkedEntityType: string,
    ): Promise<any> {
        const result = await __request(OpenAPI, {
            method: 'DELETE',
            url: `/api/LinkedItems`,
            headers: { Authorization: `Bearer ${token}` },
            query: {
                'parentId': parentId,
                'parentType': parentType,
                'linkedEntityId': linkedEntityId,
                'linkedEntityType': linkedEntityType
            }
        });
        return result;
    }

    public static async deleteAll(token: string,
        parentId: number,
        parentType: string,        
        linkedEntityType: string,
    ): Promise<any> {
        const result = await __request(OpenAPI, {
            method: 'DELETE',
            url: `/api/LinkedItems`,
            headers: { Authorization: `Bearer ${token}` },
            query: {
                'parentId': parentId,
                'parentType': parentType,
                'linkedEntityId': 0,
                'linkedEntityType': linkedEntityType
            }
        });
        return result;
    }
}