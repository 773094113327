import {
    ProductVmcLink,
    OpenAPI,
    ProductVmcLinksService
} from '../openapi';
import { ApiResult } from '../openapi/core/ApiResult';

const { post, put, get, get1, getTotalCount, delete: del } = ProductVmcLinksService;

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT as string;

export const getProductVmcLinks = async (token: string, range: string, filter?: Record<string, string>, search?: Record<string, string>) => {
    const products: ApiResult = await get(token, range, filter, search);
    return products;
};

export const getCount = async (token: string, filter?: Record<string, string>, search?: Record<string, string>): Promise<string> => {
    const result: string = await getTotalCount(token, filter, search);
    const count = result.split('/')[1];
    return count;

};

export const getProductById = async (token: string, id: number): Promise<ProductVmcLink> => {
    return await get1(token, id);
};

export const addProduct = async (token: string, newTerminal: ProductVmcLink): Promise<ProductVmcLink> => {
    return await post(token, newTerminal);
};

export const updateProduct = async (
    token: string,
    id: number,
    product: ProductVmcLink
): Promise<ProductVmcLink> => {

    return await put(token, id, product);

};

export const deleteProduct = async (token: string, id: number) => {
    await del(token, id);
};
