import {
    VmcType,
    OpenAPI,
    VmcTypeService
} from '../openapi';
import { ApiResult } from '../openapi/core/ApiResult';

const { get, get1, getTotalCount } = VmcTypeService;

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT as string;

export const getVmcTypes = async (token: string, range: string, filter?: Record<string, string>, search?: Record<string, string>) => {
    const vmcTypes: ApiResult = await get(token, range, filter, search);
    return vmcTypes;
};

export const getVmcTypeCount = async (token: string, filter?: Record<string, string>, search?: Record<string, string>): Promise<string> => {
    const result: string = await getTotalCount(token, filter, search);
    const count = result.split('/')[1];
    return count;
};

export const getVmcTypeById = async (token: string, id: string): Promise<VmcType> => {
    return await get1(token, id);
};

