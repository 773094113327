import React from 'react';
import { Location } from '../../services/openapi'
import { ApiError } from '../../services/openapi';
import { Col, Row } from 'react-bootstrap';

type LocationProps = {    
    error?: ApiError;
    location: Location;
    rowColor: string;
    className?: string;
};

const LocationItem = ({ location, rowColor, className }: LocationProps) => {
    return (
        <>
            <Row className={("qvend-table-font " + (className ?? ""))} style={{ background: rowColor, color: "black" }}>
                <Col xl={8} lg={8} md={6} sm={6} xs={9} className="qvend-table table-column-first">{location.description}</Col>
                <Col xl={2} lg={2} md={3} sm={0} xs={0} className="qvend-table d-none d-md-block amount-column">{location.vmcCount}</Col>
                <Col xl={2} lg={2} md={3} sm={6} xs={3} className="qvend-table amount-column">{location.terminalCount}</Col>
            </Row>
        </>
    );
};

export default LocationItem;
