
import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Form, FormCheck, Badge, CloseButton } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'

import { TerminalItem } from '../../../features/terminals';
import { getTerminals, getTerminalCount, getTxCount } from '../../../services/api/terminal';
import { ApiError, Terminal } from '../../../services/openapi';

import { useKeycloak } from '@react-keycloak/web'

import SearchInput from '../../../components/SearchInput';
import ListPagination from '../../../components/ListPagination';
import RefreshPull from '../../../components/RefreshPull';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { ApiResult } from '../../../services/openapi/core/ApiResult';
import CsvExport from '../../../components/exports/CsvExport';

const Terminals = () => {
    const [error, setError] = useState<ApiError | null>();
    const [terminals, setTerminals] = useState<Terminal[]>([]);    
    const [terminalCount, setTerminalCount] = useState<string>("0");    
    const [pagingString, setPagingString] = useState<string>("0-25");        
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [tableBodyHeight, setTableBodyHeight] = useState<number>(380);    
    const [formData, setFormData] = useLocalStorage<string>("formData", "");
    const [searchData, setSearchData] = useLocalStorage<Record<string, string>>("searchData", {});
    const [filterData, setFilterData] = useLocalStorage<string>("filterData", "");
    const [currentPage, setCurrentPage] = useLocalStorage<number>("currentPage", 0);
    const [siteFilter, setSiteFilter] = useLocalStorage<number[]>("siteFilter", []);
    const [siteFilterQuery, setSiteFilterQuery] = useLocalStorage<string>("siteFilterQuery", "");

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const dummyTerminal: Terminal = {
        poi: " ",
        siteIdNumber: 0,
        siteId: "",
        isConnected: true,
        siteDescription: " ",
        lastMessageReceivedDateTime: " ",
        locationId: 0,
        maxVmcs: 1,
        vmcIds: []
    };

    const setData = (data: Terminal) => {
        localStorage.setItem('Terminal', JSON.stringify(data));
    }

    const delay = async (ms: number) => {
        await new Promise(resolve => setTimeout(resolve, ms));
    }

    const csvHeaders = [
        { label: 'POI', key: 'poi' },
        { label: 'Terminal', key: 'description' },
        { label: 'Aangemaakt', key: 'created' },
        { label: 'Site ID', key: 'siteId' },
        { label: 'Aantal transacties', key: 'txCount' },
        { label: 'Laatste transactie', key: 'txDate' },
    ];

    let timer: NodeJS.Timeout | null = null;

    const submitSearch = () => {
        formData === "" ? setFormData(" ") : setFormData("");
    }

    const handleChange = () => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(submitSearch, 2000);
        setCurrentPage(0);
    }

    const handlePaging = async (e: number) => {
        do {
            await delay(1000);
        } while (isRefreshing);

        setCurrentPage(e);

        const start = ((e + 1) * 25) - 25;
        const end = ((e + 1) * 25);
        const rangeString = `${start}-${end}`
        setPagingString(rangeString);

        document.querySelector('#refresh')?.classList.remove("d-none")
        setIsRefreshing(true);

        getTerminals(keycloak.token || '', rangeString, { search: formData, Online: filterData, sites: siteFilter.join(), sitesQuery: siteFilterQuery }, searchData)
            .then((result) => {
                setTerminals(result.body);
                var totalCount = result.header.split('/')[1];
                setTerminalCount(totalCount);
                document.querySelector('#refresh')?.classList.add("d-none");
                setIsRefreshing(false);
            })
            .catch((error) => setError(error));
    }

    const handleCsvExport = (): Promise<ApiResult> => {
        const rangeString = "0";
        return getTxCount(keycloak.token || '', rangeString, { search: formData }, searchData);
    }

    const handleFilter = (e: string): void => {
        setTerminals([]);
        setFilterData(e);
        setCurrentPage(0);
    }

    const handleSearch = (e: string): void => {
        setTerminals([]);
        searchData["search"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleSearchColumn1 = (e: string): void => {
        setTerminals([]);
        searchData["description"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleSearchColumn2 = (e: string): void => {
        setTerminals([]);
        searchData["locationDescription"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleSearchColumn3 = (e: string): void => {
        setTerminals([]);
        searchData["lastMessageReceivedDateTime"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleRefreshButton = (): void => {
        setTerminals([]);
        formData === "" ? setFormData(" ") : setFormData("");
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): boolean => {
        if (event.keyCode == 13) {
            event.preventDefault();
            return false;
        }
        return true;
    }

    const init = () => {
        handlePaging(currentPage ?? 0);
        initSearchFields();
    }; 

    const initSearchFields = () => {
        Object.keys(searchData).map(x => {
            const search: HTMLInputElement | null = document.querySelector(`.${x}`) as HTMLInputElement;
            if (search) {
                search.value = searchData[x];
            }
        })
    }

    const onResize = () => {
        var e = document.querySelector(".vertical-scrollable") as HTMLElement;
        var h = window.innerHeight;
        e.style.height = (h - 284) + "px";
        e.style.maxHeight = (h - 280) + "px";
        setTableBodyHeight(h - 284);        
    };

    useEffect(() => {
        window.addEventListener("resize", onResize);        

        if (terminals?.length == 0) {
            init();
            onResize();
        }

        return () => {
            window.removeEventListener("resize", onResize);            
        };

    }, [formData, filterData]);    

    //const handleRequestCtmp = useCallback((id: number) => {
    //    requestCtmp(keycloak.token || '', id).catch((err) => setError(err));
    //}, []);

    return (
        <>
            <Row className="">                  
                <Col className="">
                    <Card className="rounded">                        
                        <Card.Body className="">
                            <Row className="d-none d-md-block">
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row className="d-none d-md-block">
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row className="">
                                <Col sm="12" className="">    
                                    <h4 className="mb-2  qvend-title">Terminals</h4>                                    
                                </Col>
                            </Row>                            
                            <Row style={{ fontSize: '11px' }}>
                                <Col md={3} xs={5} style={{ paddingRight: 0 }}>
                                    <div className="bd-example">
                                        <div>
                                            <SearchInput className="form-control search" type="search" placeholder={!searchData["search"] ? "Search" : undefined } onChange={handleSearch} />
                                        </div>                                            
                                    </div>                                    
                                </Col>
                                <Col md={3} xs={1}>
                                    <button type="button" className="btn btn-qvend" style={{ fontSize: '11px', padding: '0.5rem 0.3rem' }}>...</button>
                                </Col>                                    
                                <Col className="text-end">
                                    {keycloak && keycloak.hasResourceRole("r-admin") && (<CsvExport onGetData={handleCsvExport} headers={csvHeaders} separator={";"} filename={"terminals.csv"} className="btn btn-qvend" style={{ fontSize: '11px' }} />)}
                                    {(!keycloak || !keycloak.hasResourceRole("r-admin")) && (<button type="button" className="btn btn-qvend" style={{ fontSize: '11px' }}>Download</button>)}
                                </Col>
                            </Row>
                            <form className="" onKeyDown={handleKeyDown}>
                                <Row>
                                    <Col md={12} xs={10} style={{ paddingRight: 0 }}>
                                        <div>
                                            <fieldset className="form-control-sm" style={{ fontSize: '11px' }}>
                                                <Form.Check className="form-check-inline">
                                                    <FormCheck.Input onChange={() =>
                                                        handleFilter("")
                                                    } type="radio" name="radios" className="form-check-input" id="radio1" defaultChecked={ filterData === "" } />
                                                    <FormCheck.Label className="form-check-label" htmlFor="radio1">Allemaal</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="form-check-inline">
                                                    <FormCheck.Input onChange={() =>
                                                        handleFilter("true")
                                                    } type="radio" name="radios" className="form-check-input" id="radio2" defaultChecked={filterData === "true"}/>
                                                    <FormCheck.Label className="form-check-label" htmlFor="radio2">Online</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="form-check-inline">
                                                    <FormCheck.Input onChange={() =>
                                                        handleFilter("false")
                                                    } type="radio" name="radios" className="form-check-input" id="radio3" defaultChecked={filterData === "false"}/>
                                                    <FormCheck.Label className="form-check-label" htmlFor="radio3">Offline</FormCheck.Label>
                                                </Form.Check>
                                                {(siteFilter && siteFilter.length > 0 || siteFilterQuery && siteFilterQuery.length > 2) ? <span className="text-end">
                                                    <Badge pill bg="dark" className="p-1 " onClick={() => { setSiteFilter([]); setSiteFilterQuery(""); setTerminals([]); setCurrentPage(0); formData === "" ? setFormData(" ") : setFormData(""); window.localStorage.removeItem("searchDataSites") }}><CloseButton variant="white" /> Sites</Badge></span> : null}
                                            </fieldset>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                            <Row className="">
                                <Col sm="12" xs="12" className="mt-2">
                                    <RefreshPull isRefreshing={isRefreshing} onSetPage={handlePaging} />
                                    <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                        <div>
                                            <Row className="qvend-table-font" style={{ background: "#2b2e83", color: "#FFFFFF", overflowY: "scroll"  }}>
                                                <Col md={5} xs={6} className="qvend-table table-column-first">Terminal</Col>
                                                <Col md={4} xs={6} className="qvend-table">Locatie</Col>
                                                <Col md={3} xs={0} className="qvend-table d-none d-md-block">Laatst gezien</Col>                                                
                                            </Row>
                                        </div>
                                        <div className="vertical-scrollable" style={{ overscrollBehaviorY: 'contain' }}>
                                            <Row className={("qvend-table-font")} style={{ background: "#cfd5ea", color: "black" }}>
                                                <Col md={5} xs={6} className="qvend-table search-in-row-first"><SearchInput className="form-control description" type="search" onChange={handleSearchColumn1} /></Col>
                                                <Col md={4} xs={6} className="qvend-table search-in-row-padding"><SearchInput className="form-control locationDescription" type="search" onChange={handleSearchColumn2} /></Col>
                                                <Col md={3} xs={0} className="qvend-table search-in-row-padding d-none d-md-block"><SearchInput className="form-control lastMessageReceivedDateTime" type="search" onChange={handleSearchColumn3} /></Col>
                                            </Row>
                                            {terminals.map((term: Terminal, index) => (
                                                <Link to="/dashboard/details/terminal" onClick={() => setData(term)} key={term.id}>
                                                    <TerminalItem
                                                        key={term.id}
                                                        terminal={term}
                                                        error={error === null ? undefined : error}                                                        
                                                        rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"}
                                                    />
                                                </Link>
                                            ))}
                                            {                                                
                                                //Array.from({ ...dummyTerminal, length: (tableBodyHeight / 16.5) - terminals.length } as unknown as Terminal[]).map((terminal: Terminal, index) => (
                                                //    <TerminalItem
                                                //        key={index + terminals.length}
                                                //        terminal={dummyTerminal}
                                                //        rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"}
                                                //    />
                                                //))
                                            }
                                        </div>
                                    </div>
                                </Col>                              
                            </Row>
                            <ListPagination
                                articlesCount={terminalCount}
                                currentPage={currentPage}
                                onSetPage={handlePaging}
                                onRefresh={handleRefreshButton} />
                        </Card.Body>
                    </Card>
                </Col>                                    
            </Row>
        </>
    )
}

export default Terminals
