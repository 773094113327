import React, { ChangeEventHandler } from 'react';
import { Site } from '../../services/openapi'
import { ApiError } from '../../services/openapi';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type SiteProps = {    
    error?: ApiError;
    site: Site;
    rowColor: string;
    className?: string;
    isDisabled?: boolean | undefined;
    isChecked: boolean;
    handleChange: ChangeEventHandler<HTMLInputElement>;
};

const SiteItem = ({ site, isDisabled, isChecked, rowColor, className, handleChange }: SiteProps) => {
    return (
        <>

            <Col xl={1} lg={1} md={1} sm={1} xs={3} className="qvend-table q-vend-detail-table-col table-column-first-check" style={{cursor:"default"}} onClick={e => e.preventDefault()}>{
                    <span>
                    <Form.Check                        
                        onChange={(e) => { handleChange(e); }}
                        disabled={isDisabled}
                        checked={isChecked} /></span>
                }
                </Col>

            <Col xl={2} lg={2} md={2} sm={2} xs={4} className="qvend-table q-vend-detail-table-col">{site.siteId}</Col>
            <Col xl={4} lg={4} md={4} sm={9} xs={5} className="qvend-table q-vend-detail-table-col">{site.description}</Col>
            <Col xl={5} lg={5} md={5} sm={0} xs={0} className="qvend-table q-vend-detail-table-col d-none d-md-block">{site.customerName}</Col>                             
        </>
    );
};

export default SiteItem;
