import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const IdleTimeOutModal = ({ showButton, showModal, handleClose }) => {

    let button;

    if (showButton) {
        button = <Button variant="primary" onClick={handleClose}>Ingelogd blijven</Button>
    } else {
        button = null;
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Waarschuwing</Modal.Title>
            </Modal.Header>
            <Modal.Body>Over 1 minuut wordt u automatisch uitgelogd.</Modal.Body>
            <Modal.Footer>
                { button }
            </Modal.Footer>
        </Modal>
    )
}