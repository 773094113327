import React, { useEffect, useState } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloack";
import { AuthClientEvent } from "@react-keycloak/core";
import Nav from "./components/Nav";
import IndexRouters from "./router/index"
import { useNavigate } from "react-router-dom";

import { IdleTimeOutModal } from './components/IdleTimeoutModal'

import "./assets/scss/hope-ui.scss"
import "./assets/scss/dark.scss"
import "./assets/scss/rtl.scss"
import "./assets/scss/custom.scss"
import "./assets/scss/customizer.scss"


function App() {
    const maxTokenExpiration = 780;
    const maxSessionExpiration = 3480;
    const [tokenTimer, setTokenTimer] = useState(maxTokenExpiration);
    const [sessionTimer, setSessionTimer] = useState(maxSessionExpiration);
    const [showModal, setShowModal] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const [lastUserActionTime, setLastUserActionTime] = useState<Date>(new Date());
    const navigate = useNavigate();

    useEffect(() => {
        const myInterval = setInterval(() => {
            if (tokenTimer > 0) {
                setTokenTimer(tokenTimer - 1);
            }
            if (sessionTimer > 0) {
                setSessionTimer(sessionTimer - 1);
            }
        }, 1000);

        if (sessionTimer == 0) {
            setShowButton(false);
            setShowModal(true);
        }
        else if (tokenTimer == 0) {
            setShowButton(true);
            setShowModal(true);
        }
        else {
            setShowModal(false);
        }

        return () => {
            clearInterval(myInterval);
        };
    });

    const refreshLastUserActionTime = () => {
        setLastUserActionTime(new Date())
    }

    const onKeycloakEvent = (event: AuthClientEvent, error: any) => {
        if (event === 'onReady') {
            return;
        }

        if (error) {
            console.log('onKeycloakEvent', error)
        }

        if (event === 'onAuthSuccess') {
            navigate('dashboard');
        }

        if (event === 'onAuthRefreshError' || event === 'onAuthError') {
            keycloak.clearToken();
            keycloak.logout();
        }

        if (event === 'onTokenExpired') {
            const currentTime = new Date().getTime();
            if (currentTime - lastUserActionTime.getTime() < 120000) {
                keycloak.updateToken(1).then(function (refreshed: boolean) {
                    if (refreshed) {
                        console.log('Token refreshed');
                    } else {
                        console.log('Token still valid');
                        setTokenTimer(maxTokenExpiration);
                    }
                }).catch(function () {
                    console.log('Token refresh failed');
                    setTokenTimer(maxTokenExpiration);
                });
                setTokenTimer(maxTokenExpiration);
            }
            else {
                keycloak.clearToken();
                keycloak.login({ prompt: 'login' });
            }
        }

        if (event === 'onAuthRefreshSuccess') {
            setTokenTimer(maxTokenExpiration);
        }
    }

    return (
        <>
            <div className="">
                <IdleTimeOutModal
                    showModal={showModal}
                    showButton={showButton}
                    handleClose={() => {
                        if (tokenTimer == 0 && sessionTimer > 0) {
                            keycloak.updateToken(120).then(function (refreshed: boolean) {
                                if (refreshed) {
                                    console.log('Token refreshed');
                                } else {
                                    console.log('Token still valid');
                                    setTokenTimer(maxTokenExpiration);
                                }
                            }).catch(function () {
                                console.log('Token refresh failed');
                                setTokenTimer(maxTokenExpiration);
                            });
                        }
                        else {
                            setSessionTimer(maxSessionExpiration);
                        }
                        setShowModal(false);
                    }}
                />
            </div>
            <div className="App main-content" onClick={() => refreshLastUserActionTime()} onKeyPress={() => refreshLastUserActionTime()}>
                <ReactKeycloakProvider authClient={keycloak} onEvent={onKeycloakEvent} initOptions={{ onLoad: 'login-required' }}>
                    <Nav />
                    <IndexRouters />
                </ReactKeycloakProvider>
            </div>
        </>
    );
}

export default App;