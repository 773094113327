/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagingHeader } from '../models/PagingHeader';
import type { TerminalMdbSetting } from '../models/TerminalMdbSetting';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { requestHdr as __requestHdr } from '../core/request';
import TerminalMdbSettingDto from '../../../views/dashboard/details/terminal/TerminalMdbSettingDto';

export class TerminalMdbSettingsService {
    
    /**
     * @param id 
     * @returns Terminal Success
     * @throws ApiError
     */
    public static async get1(token: string,
        id: number,
    ): Promise<TerminalMdbSettingDto> {
        return await __request(OpenAPI, {
            method: 'GET',
            url: `/api/TerminalMdbSettings/${id}`,
            headers: { Authorization: `Bearer ${token}` }
        });
    }

    /**
  * @param requestBody 
  * @returns Terminal Success
  * @throws ApiError
  */
    public static async post(token: string,
        requestBody?: TerminalMdbSetting,
    ): Promise<TerminalMdbSetting> {
        return await __request(OpenAPI, {
            method: 'POST',
            url: `/api/TerminalMdbSettings`,
            body: requestBody,
            headers: { Authorization: `Bearer ${token}` }
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async put(token: string,
        id: number,
        requestBody?: TerminalMdbSettingDto,
    ): Promise<any> {
        const result = await __request(OpenAPI, {
            method: 'PUT',
            url: `/api/TerminalMdbSettings/${id}`,
            body: requestBody,
            headers: { Authorization: `Bearer ${token}` }
        });
        return result;
    }


    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static async delete(token: string,
        id: number,
    ): Promise<any> {
        const result = await __request(OpenAPI, {
            method: 'DELETE',
            url: `/api/TerminalMdbSettings/${id}`,
            headers: { Authorization: `Bearer ${token}` }
        });
        return result;
    }

}