/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagingHeader } from '../models/PagingHeader';
import type { VmcStatusDto as VmcStatus} from '../models/VmcStatusDto';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { requestHdr as __requestHdr } from '../core/request';
import { requestApiResult as __requestApiResult } from '../core/request';
import { ApiResult } from '../core/ApiResult';


export class VmcsService {

    /**
     * @param pagingHeader 
     * @param filter 
     * @returns VmcStatusDto Success
     * @throws ApiError
     */
    public static async get(token: string,
        pagingRange: string,
        filter?: Record<string, string>,
        search?: Record<string, string>
): Promise<ApiResult> {
        return await __requestApiResult(OpenAPI, {
            method: 'GET',
            url: `/api/Vmcs`,
            headers: {                
                Range: `vmcs=${pagingRange}`,
                Authorization: `Bearer ${token}`
            },
            query: {
                'filter': filter,
                'search': search
            },
            responseHeader: 'Content-Range'
        });
    }

    /**
     * @param id 
     * @returns VmcStatusDto Success
     * @throws ApiError
     */
    public static async get1(token: string,
id: string,
): Promise<VmcStatus> {
        return await __request(OpenAPI, {
            method: 'GET',
            url: `/api/Vmcs/${id}`,
            headers: { Authorization: `Bearer ${token}` }
        });
    }

    public static async getTotalCount(token: string,
        filter?: Record<string, string>,
        search?: Record<string, string>
    ): Promise<string> {
        return __requestHdr(OpenAPI, {
            method: 'GET',
            url: '/api/Vmcs',
            headers: { Range: 'vmcs=0-1', Authorization: `Bearer ${token}` },
            query: {
                'filter': filter,
                'search': search
            },
            responseHeader: 'Content-Range'
        });
    }

    public static async getOnlineCount(token: string
    ): Promise<string> {
        return __requestHdr(OpenAPI, {
            method: 'GET',
            url: '/api/Vmcs',
            headers: { Range: 'vmcs=0-1', Authorization: `Bearer ${token}` },
            query: {
                'filter': { Online: 'true' },
            },
            responseHeader: 'Content-Range'
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async put(token: string,
        id: number,
        requestBody?: VmcStatus,
    ): Promise<any> {
        const result = await __request(OpenAPI, {
            method: 'PUT',
            url: `/api/Vmcs/${id}`,
            body: requestBody,
            headers: { Authorization: `Bearer ${token}` }
        });
        return result;
    }
}