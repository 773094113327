import {
    Alert,
    OpenAPI,
    AlertService
} from '../openapi';
import { ApiResult } from '../openapi/core/ApiResult';

const { get, get1, put, getTotalCount } = AlertService;

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT as string;

export const getAlerts = async (token: string, range: string, filter?: Record<string, string>, search?: Record<string, string>) => {
    const locations: ApiResult = await get(token, range, filter, search);
    return locations;
};

export const getAlertCount = async (token: string, filter?: Record<string, string>, search?: Record<string, string>): Promise<string> => {
    const result: string = await getTotalCount(token, filter, search);
    const count = result.split('/')[1];
    return count;
};

export const getAlertById = async (token: string, id: string): Promise<Alert> => {
    return await get1(token, id);
};

export const updateAlert = async (
    token: string,
    id: number,
    alert: Alert
): Promise<Alert> => {
    return await put(token, id, alert);
};
