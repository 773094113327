/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagingHeader } from '../models/PagingHeader';
import type { Location} from '../models/Location';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { requestHdr as __requestHdr } from '../core/request';
import { requestApiResult as __requestApiResult } from '../core/request';
import { ApiResult } from '../core/ApiResult';
import { Alert } from '../models/Alert';


export class AlertService {

    /**
     * @param pagingHeader 
     * @param filter 
     * @returns VmcStatusDto Success
     * @throws ApiError
     */
    public static async get(token: string,
        pagingRange: string,
        filter?: Record<string, string>,
        search?: Record<string, string>
): Promise<ApiResult> {
        return await __requestApiResult(OpenAPI, {
            method: 'GET',
            url: `/api/Alerts`,
            headers: {                
                Range: `alerts=${pagingRange}`,
                Authorization: `Bearer ${token}`
            },
            query: {
                'filter': filter,
                'search': search
            },
            responseHeader: 'Content-Range'
        });
    }

    /**
     * @param id 
     * @returns VmcStatusDto Success
     * @throws ApiError
     */
    public static async get1(token: string,
id: string,
): Promise<Alert> {
        return await __request(OpenAPI, {
            method: 'GET',
            url: `/api/Alerts/${id}`,
            headers: { Authorization: `Bearer ${token}` }
        });
    }

    public static async getTotalCount(token: string,
        filter?: Record<string, string>,
        search?: Record<string, string>
    ): Promise<string> {
        return await __requestHdr(OpenAPI, {
            method: 'GET',
            url: '/api/Alerts',
            headers: { Range: 'alerts=0-1', Authorization: `Bearer ${token}` },
            query: {
                'filter': filter,
                'search': search
            },
            responseHeader: 'Content-Range'
        });
    }

    /**
 * @param id 
 * @param requestBody 
 * @returns any Success
 * @throws ApiError
 */
    public static async put(token: string,
        id: number,
        requestBody?: Alert,
    ): Promise<any> {
        const result = await __request(OpenAPI, {
            method: 'PUT',
            url: `/api/Alerts/${id}`,
            headers: { Authorization: `Bearer ${token}` },
            body: requestBody,
        });
        return result;
    }
}