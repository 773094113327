import TerminalMdbSettingDto from '../../views/dashboard/details/terminal/TerminalMdbSettingDto';
import {
    TerminalMdbSetting,
    OpenAPI,
    TerminalMdbSettingsService
} from '../openapi';

const { put, get1, post, delete: del } = TerminalMdbSettingsService;

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT as string;

export const getTerminalMdbSettingById = async (token: string, id: number): Promise<TerminalMdbSettingDto> => {
    return await get1(token, id);
};

export const addTerminalMdbSetting = async (token: string, newTerminalMdb: TerminalMdbSetting): Promise<TerminalMdbSetting> => {
    return await post(token, newTerminalMdb);
};

export const updateTerminalMdbSetting = async (
    token: string,
    id: number,
    terminal: TerminalMdbSettingDto
): Promise<TerminalMdbSetting> => {
    return await put(token, id, terminal);
};

export const deleteTerminalMdbSetting = async (token: string, id: number) => {
    await del(token, id);
};
