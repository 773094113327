import React from 'react';
import { Row, Col} from 'react-bootstrap'

const ListPagination = props => {
    if (props.articlesCount == 0) {
        return null;
    }

    const pageCount = Math.ceil(props.articlesCount / 25);

    if (props.currentPage > pageCount - 1) {
        props.onSetPage(pageCount - 1);
    }

    const range = [];
    let pagingObject = {};

    pagingObject.page = 0;
    pagingObject.text = "<<";
    if (props.currentPage == 0) {
        pagingObject.disabled = true;
    }
    range.push(pagingObject);

    //for (let i = 0; i < Math.ceil(props.articlesCount / 25); ++i) {
    //    range.push(i);
    //}    

    if (props.currentPage > 0) {
        pagingObject = {};
        pagingObject.page = props.currentPage - 1;
        pagingObject.text = "<";
        pagingObject.disabled = false;
        range.push(pagingObject);
    }
    else {
        pagingObject = {};
        pagingObject.page = 0;
        pagingObject.text = "<";
        pagingObject.disabled = true;
        range.push(pagingObject);
    }
    
    if (props.currentPage < pageCount - 1) {
        pagingObject = {};
        pagingObject.page = props.currentPage + 1;
        pagingObject.text = ">";
        pagingObject.disabled = false;
        range.push(pagingObject);
    }
    else {
        pagingObject = {};
        pagingObject.page = pageCount - 1;
        pagingObject.text = ">";
        pagingObject.disabled = true;
        range.push(pagingObject);
    }

    pagingObject = {};
    pagingObject.page = pageCount - 1;
    pagingObject.text = ">>";

    if (pageCount - 1 === props.currentPage) {
        pagingObject.disabled = true;
    }
    range.push(pagingObject);

    const setPage = page => props.onSetPage(page);
    const refresh = () => props.onRefresh();

    return (
        <>
        <Row>
            <Col>
                <nav>
                    <ul className="pagination">

                        {
                            range.map((v, idx) => {
                                const onClick = ev => {
                                    ev.preventDefault();
                                    if (!v.disabled) {
                                        setPage(v.page);
                                    }
                                };
                                return (
                                    <li
                                        onClick={onClick}
                                        key={idx}>
                                        {!v.disabled ? <a className="page-link" href="">{v.text}</a> : <div className="page-link" style={{ color: "gray" }}>{v.text}</div>}
                                    </li>
                                );
                            })
                        }

                            <li className="d-none d-md-block"
                                onClick={ev => { ev.preventDefault(); refresh(); }}
                                key='10'
                            >
                                <span className="page-link">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 512 512"><title>refresh</title><path d="M320,146s24.36-12-64-12A160,160,0,1,0,416,294" style={{ fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "32px" }} /><polyline points="256 58 336 138 256 218" style={{ fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "32px" }} /></svg>
                            </span>
                            </li>
                    </ul>
                </nav>
            </Col>           
            <Col className="text-end">
                    <div className="qvend-table-font">{props.currentPage + 1} van {pageCount} </div>
            </Col>
            </Row>
            </>
    );
};

export default ListPagination;