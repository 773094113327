import React from 'react'


//router
import { Routes, Route } from 'react-router'
//layoutpages
import Default from '../layouts/dashboard/default'
import PrivateRoute from "../routes/PrivateRoute";

const IndexRouters = () => {
    return (
        <>
            <Routes>                
                <Route
                    path="/dashboard/*"
                    element={
                        <PrivateRoute>
                            <Default/>
                        </PrivateRoute>
                    }
                />                
            </Routes>
        </>
    )
}

export default IndexRouters
