/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagingHeader } from '../models/PagingHeader';
import type { Location} from '../models/Location';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { requestHdr as __requestHdr } from '../core/request';
import { requestApiResult as __requestApiResult } from '../core/request';
import { ApiResult } from '../core/ApiResult';


export class ScreenConfigService {   
    /**
     * @param id 
     * @returns VmcStatusDto Success
     * @throws ApiError
     */
    public static async get1(token: string,
id: string,
): Promise<string> {
        return await __request(OpenAPI, {
            method: 'GET',
            url: `/api/ScreenConfig/${id}`,
            headers: { Authorization: `Bearer ${token}` }
        });
    }    
}