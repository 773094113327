/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagingHeader } from '../models/PagingHeader';
import type { Terminal } from '../models/Terminal';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { requestHdr as __requestHdr } from '../core/request';
import { requestApiResult as __requestApiResult } from '../core/request';
import { ApiResult } from '../core/ApiResult';

export class TerminalsService {

    /**
     * @param pagingHeader 
     * @param filter 
     * @returns Terminal Success
     * @throws ApiError
     */
    public static async get(token: string,
        pagingRange: string,
        filter?: Record<string, string>,
        search?: Record<string, string>
    ): Promise<ApiResult> {
        return await __requestApiResult(OpenAPI, {
            method: 'GET',
            url: `/api/Terminals`,
            headers: {                
                Range: `terminals=${pagingRange}`,
                Authorization: `Bearer ${token}`
            },
            query: {
                'filter': filter,
                'search': search
            },
            responseHeader: 'Content-Range'
        });
    }

    /**
     * @param requestBody 
     * @returns Terminal Success
     * @throws ApiError
     */
    public static async post(token: string,
        requestBody?: Terminal,
    ): Promise<Terminal> {
        return await __request(OpenAPI, {
            method: 'POST',
            url: `/api/Terminals`,
            body: requestBody,
            headers: { Authorization: `Bearer ${token}` }
        });
    }

    /**
     * @param id 
     * @returns Terminal Success
     * @throws ApiError
     */
    public static async get1(token: string,
        id: number,
    ): Promise<Terminal> {
        return await __request(OpenAPI, {
            method: 'GET',
            url: `/api/Terminals/${id}`,
            headers: { Authorization: `Bearer ${token}` }
        });
    }

    public static async getTotalCount(token: string,
        filter?: Record<string, string>,
        search?: Record<string, string>
    ): Promise<string> {
        return __requestHdr(OpenAPI, {
            method: 'GET',
            url: '/api/Terminals',
            headers: { Range: 'terminals=0-1', Authorization: `Bearer ${token}` },
            query: {
                'filter': filter,
                'search': search
            },
            responseHeader: 'Content-Range'
        });
    }

    public static async getOnlineCount(token: string
    ): Promise<string> {
        return __requestHdr(OpenAPI, {
            method: 'GET',
            url: '/api/Terminals',
            headers: { Range: 'terminals=0-1', Authorization: `Bearer ${token}` },
            query: {
                'filter': { Online: 'true' },
            },
            responseHeader: 'Content-Range'            
        });
    }

    public static async getTxCountPerTerminal(token: string,
        pagingRange: string,
        filter?: Record<string, string>,
        search?: Record<string, string>
    ): Promise<ApiResult> {
        return await __requestApiResult(OpenAPI, {
            method: 'GET',
            url: '/api/Query',
            headers: {
                Range: `terminals=${pagingRange}`,
                Authorization: `Bearer ${token}`
            },
            query: {
                'filter': filter,
                'search': search,
                'command': 'transactionCountPerTerminal'
            },
            responseHeader: 'Content-Range'
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async put(token: string,
        id: number,
        requestBody?: Terminal,
    ): Promise<any> {
        const result = await __request(OpenAPI, {
            method: 'PUT',
            url: `/api/Terminals/${id}`,
            body: requestBody,
            headers: { Authorization: `Bearer ${token}` }
        });
        return result;
    }

    public static async ctmp(token: string,
        id: number        
    ): Promise<any> {
        const result = await __request(OpenAPI, {
            method: 'PUT',
            url: `/api/Terminals/${id}/ctmp`,            
            headers: { Authorization: `Bearer ${token}` }
        });
        return result;
    }

    public static async conf(token: string,
        id: number,
        swapCode: string
    ): Promise<any> {
        const result = await __request(OpenAPI, {
            method: 'PUT',
            url: `/api/Terminals/${id}/configure/${swapCode}`,
            headers: { Authorization: `Bearer ${token}` }
        });
        return result;
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static async delete(token: string,
        id: number,
    ): Promise<any> {
        const result = await __request(OpenAPI, {
            method: 'DELETE',
            url: `/api/Terminals/${id}`,
            headers: { Authorization: `Bearer ${token}` }
        });
        return result;
    }

}