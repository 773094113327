import React, { useCallback, useEffect, useState } from 'react';

interface refreshPullProps {
    isRefreshing: boolean,
    onSetPage: (page: number) => void
}

function RefreshPull(props : refreshPullProps) {

    const [startPoint, setStartPoint] = useState<number>(0);
    const [pullChange, setPullChange] = useState<number>(0);
    const setPage = (page : number) => props.onSetPage(page);
    
    const initLoading = () => {
        if (props.isRefreshing) {
            return;
        }

        //document.querySelector('#refresh')?.classList.remove("d-none");

        setPage(0);
    };

    const pullStart = (e: TouchEvent) => {

        //    document.querySelector('.refresh')?.classList.remove("d-none");

        const screenY = e.targetTouches[0].screenY;
        setStartPoint(screenY);
    };

    const pull = (e: TouchEvent) => {
        /**
         * get the current user touch event data
         */
        const touch = e.targetTouches[0];
        /**
         * get the touch position on the screen's Y axis
         */
        const screenY = touch.screenY;
        /**
         * The length of the pull
         *
         * if the start touch position is lesser than the current touch position, calculate the difference, which gives the `pullLength`
         *
         * This tells us how much the user has pulled
         */
        let pullLength = startPoint < screenY ? Math.abs(screenY - startPoint) : 0;
        setPullChange(pullLength);
        //console.log({ screenY, startPoint, pullLength, pullChange });
    };

    const endPull = (e: TouchEvent) => {
        console.log('sp: ' + startPoint);
        if (pullChange > 200) {
            setStartPoint(0);
            setPullChange(0);
            initLoading()
        }
        else {
            setStartPoint(0);
            setPullChange(0);
        }
    };

    useEffect(() => {
        window.addEventListener("touchstart", pullStart);
        window.addEventListener("touchmove", pull);
        window.addEventListener("touchend", endPull);        

        return () => {
            window.removeEventListener("touchstart", pullStart);
            window.removeEventListener("touchmove", pull);
            window.removeEventListener("touchend", endPull);
        };
    }, [startPoint, pullChange]);    

    return (
        <div      
            className="text-center"
            style={{ marginTop: pullChange / 3.118 || "" }}
        >
            <div id="refresh" className="d-none spinner-border refresh">

            </div>
        </div>        
    );
}


export default RefreshPull;