import {
    Transaction,
    OpenAPI,
    TransactionsService
} from '../openapi';
import { ApiResult } from '../openapi/core/ApiResult';

const { post, put, get, get1, getTotalCount, delete: del } = TransactionsService;

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT as string;

export const getTransactions = async (token: string, range: string, filter?: Record<string, string>, search?: Record<string, string>) => {
    const txs: ApiResult = await get(token, range, filter, search);
    return txs;
};

export const getTransactionCount = async (token: string, filter?: Record<string, string>, search?: Record<string, string>): Promise<string> => {
    const result: string = await getTotalCount(token, filter, search);
    const count = result.split('/')[1];
    return count;
};

export const getTransactionById = async (token: string, id: number): Promise<Transaction> => {
    return await get1(token, id);
};

export const addTransaction = async (token: string, newTx: Transaction): Promise<Transaction> => {
    return await post(token, newTx);
};

export const updateTransaction = async (
    token: string,
    id: number,
    tx: Transaction
): Promise<Transaction> => {
    return await put(token, id, tx);
};

export const deleteTransaction = async (token: string, id: number) => {
    await del(token, id);
};
