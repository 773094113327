/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagingHeader } from '../models/PagingHeader';
import type { Setting } from '../models/Setting';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { requestHdr as __requestHdr } from '../core/request';

export class SettingService {

    /**
     * @param pagingHeader 
     * @param filter 
     * @returns VmcStatusDto Success
     * @throws ApiError
     */
    public static async get(token: string,
        pagingRange: string,
        filter?: Record<string, string>,
): Promise<Array<Setting>> {
        return await __request(OpenAPI, {
            method: 'GET',
            url: `/api/Settings`,
            headers: {                
                Range: `settings=${pagingRange}`,
                Authorization: `Bearer ${token}`
            },
            query: {
                'filter': filter,
            },
        });
    }

    /**
     * @param id 
     * @returns VmcStatusDto Success
     * @throws ApiError
     */
    public static async get1(token: string,
id: string,
): Promise<Setting> {
        return await __request(OpenAPI, {
            method: 'GET',
            url: `/api/Settings/${id}`,
            headers: { Authorization: `Bearer ${token}` }
        });
    }

    public static async getTotalCount(token: string,
        filter?: Record<string, string>
    ): Promise<string> {
        return __requestHdr(OpenAPI, {
            method: 'GET',
            url: '/api/Settings',
            headers: { Range: 'locations=0-1', Authorization: `Bearer ${token}` },
            query: {
                'filter': filter,
            },
            responseHeader: 'Content-Range'
        });
    }
}