/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagingHeader } from '../models/PagingHeader';
import type { ProductVmcLink } from '../models/ProductVmcLink';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { requestHdr as __requestHdr } from '../core/request';   
import { requestApiResult as __requestApiResult } from '../core/request';
import { ApiResult } from '../core/ApiResult';


export class ProductVmcLinksService {

    /**
     * @param pagingHeader 
     * @returns Product Success
     * @throws ApiError
     */
    public static async get(token: string,
        pagingRange: string,
        filter?: Record<string, string>,
        search?: Record<string, string>
): Promise<ApiResult> {
        return await __requestApiResult(OpenAPI, {
            method: 'GET',
            url: `/api/ProductVmcLinks`,
            headers: {
                Range: `productVmcLinks=${pagingRange}`,
                Authorization: `Bearer ${token}`
            },
            query: {
                'filter': filter,
                'search': search
            },           
            responseHeader: 'Content-Range'
        });        
    }

    /**
     * @param requestBody 
     * @returns Product Success
     * @throws ApiError
     */
    public static async post(token: string,
requestBody?: ProductVmcLink,
    ): Promise<ProductVmcLink> {
        return await __request(OpenAPI, {
            method: 'POST',
            url: `/api/ProductVmcLinks`,
            headers: { Authorization: `Bearer ${token}` },
            body: requestBody,
        });        
    }

    /**
     * @param id 
     * @returns Product Success
     * @throws ApiError
     */
    public static async get1(token: string,
id: number,
    ): Promise<ProductVmcLink> {
        return await __request(OpenAPI, {
            method: 'GET',
            url: `/api/ProductVmcLinks/${id}`,
            headers: { Authorization: `Bearer ${token}` }
        });        
    }

    public static async getTotalCount(token: string,
        filter?: Record<string, string>,
        search?: Record<string, string>
    ): Promise<string> {
        return __requestHdr(OpenAPI, {
            method: 'GET',
            url: '/api/ProductVmcLinks',
            headers: { Range: 'productVmcLinks=0-1', Authorization: `Bearer ${token}` },
            query: {
                'filter': filter,
                'search': search
            },
            responseHeader: 'Content-Range'
        });
    }


    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async put(token: string,
        id: number,
        requestBody?: ProductVmcLink,
): Promise<any> {
        const result = await __request(OpenAPI, {
            method: 'PUT',
            url: `/api/ProductVmcLinks/${id}`,
            headers: { Authorization: `Bearer ${token}` },
            body: requestBody,
        });
        return result;
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static async delete(token: string,
id: number,
): Promise<any> {
        const result = await __request(OpenAPI, {
            method: 'DELETE',
            url: `/api/ProductVmcLinks/${id}`,
            headers: { Authorization: `Bearer ${token}` }
        });
        return result;
    }

}