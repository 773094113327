import React from 'react';
import { Terminal } from '../../services/openapi'
import { ApiError } from '../../services/openapi';
import { Row, Col, Button } from 'react-bootstrap'


type TerminalItemProps = {
    deleteTerminal?: (id: number) => void;
    error?: ApiError;
    terminal: Terminal;
    updateTerminal?: (id: number, tx: Terminal) => void;
    requestCtmp?: (id: number) => void;
    rowColor: string;
};

const TerminalItem = ({ terminal, requestCtmp, updateTerminal, deleteTerminal, rowColor }: TerminalItemProps) => {
    return (
        <>
            <Row className="qvend-table-font" style={{ background: (terminal.isConnected ? rowColor : "orange"), color: "black" }}>
                <Col md={5} xs={6} className="qvend-table table-column-first description-column">{terminal.description ?? terminal.poi}</Col>
                <Col md={4} xs={6} className="qvend-table qvend-table-font">{terminal.locationDescription}</Col>
                <Col md={3} xs={0} className="qvend-table d-none d-md-block">{terminal.lastMessageReceivedDateTime}</Col>
                {/*<Col md={2} xs={4} className="qvend-table qvend-table-font"><span className="rounded-pill" onClick={() => requestCtmp ? requestCtmp((!(terminal.id === undefined) ? terminal.id : 0)) : 0}>*/}
                    
                {/*    {requestCtmp !== undefined ?*/}
                {/*        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*            <g clipPath="url(#clip0_429_11133)">*/}
                {/*                <circle cx="12" cy="12" r="9" stroke="#292929" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />*/}
                {/*                <path d="M14 12L11 13.7321L11 10.2679L14 12Z" stroke="#292929" strokeWidth="2.5" strokeLinejoin="round" />*/}
                {/*            </g>*/}
                {/*            <defs>*/}
                {/*                <clipPath id="clip0_429_11133">*/}
                {/*                    <rect width="24" height="24" fill="white" />*/}
                {/*                </clipPath>*/}
                {/*            </defs>*/}
                {/*        </svg>*/}
                {/*        : null*/}
                {/*    }*/}
                {/*</span></Col>*/}
            </Row>
        </>
    );
};

export default TerminalItem;
