/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Transaction } from '../models/Transaction';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { requestHdr as __requestHdr } from '../core/request';
import { requestApiResult as __requestApiResult } from '../core/request';
import { ApiResult } from '../core/ApiResult';

export class TransactionsService {

    /**
     * @returns Transaction Success
     * @throws ApiError
     */
    public static get(token: string,
        pagingRange: string,
        filter?: Record<string, string>,
        search?: Record<string, string>
    ): CancelablePromise<ApiResult> {
        return __requestApiResult(OpenAPI, {
            method: 'GET',
            url: '/api/Transactions',
            headers: {
                Range: `transactions=${pagingRange}`,
                Authorization: `Bearer ${token}`
            },
            query: {
                'filter': filter,
                'search': search
            },
            responseHeader: 'Content-Range'
        });
    }

    /**
     * @param requestBody 
     * @returns Transaction Success
     * @throws ApiError
     */
    public static post(token: string,
requestBody?: Transaction,
): CancelablePromise<Transaction> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Transactions',
            body: requestBody,
            mediaType: 'application/json',
            headers: { Authorization: `Bearer ${token}` }
        });
    }

    /**
     * @param id 
     * @returns Transaction Success
     * @throws ApiError
     */
    public static get1(token: string,
id: number,
): CancelablePromise<Transaction> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Transactions/{id}',
            path: {
                'id': id,
            },
            headers: { Authorization: `Bearer ${token}` }
        });
    }

    public static getTotalCount(token: string,
        filter?: Record<string, string>,
        search?: Record<string, string>
    ): CancelablePromise<string> {
        return __requestHdr(OpenAPI, {
            method: 'GET',
            url: '/api/Transactions',
            headers: { Range: 'transactions=0-1', Authorization: `Bearer ${token}` },
            query: {
                'filter': filter,
                'search': search
            },
            responseHeader: 'Content-Range'
        });        
    }


    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static put(token: string,
id: number,
requestBody?: Transaction,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Transactions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            headers: { Authorization: `Bearer ${token}` }
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static delete(token: string,
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Transactions/{id}',
            path: {
                'id': id,
            },
            headers: { Authorization: `Bearer ${token}` }
        });
    }
}