import {
    LinkedItem,
    OpenAPI,
    LinkedItemsService
} from '../openapi';

const { post, postArray, get, delete: del, deleteAll } = LinkedItemsService;

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT as string;

export const getLinkedItems = async (token: string, parentId: number, parentType: string, linkedEntityType: string) => {
    const items: number[] = await get(token, parentId, parentType, linkedEntityType);
    return items;
};

export const addLinkedItem = async (token: string, newItem: LinkedItem) => {
    await post(token, newItem);
};

export const addLinkedItems = async (token: string, parentId: number, parentType: string, linkedEntityType: string, linkedEntityIds: number[]) => {
    var items = linkedEntityIds.map<LinkedItem>((x: number) => { return { parentId: parentId, parentType: parentType, linkedEntityType: linkedEntityType, linkedEntityId: x }; })
    await postArray(token, items);
};


export const deleteLinkedItem = async (token: string, parentId: number, parentType: string, linkedEntityId: number, linkedEntityType: string) => {
    await del(token, parentId, parentType, linkedEntityId, linkedEntityType);
};

export const deleteAllLinkedItems = async (token: string, parentId: number, parentType: string, linkedEntityType: string) => {
    await deleteAll(token, parentId, parentType, linkedEntityType);
};