import {
    Setting,
    OpenAPI,
    SettingService
} from '../openapi';

const { get, get1 } = SettingService;

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT as string;

export const getSettings = async (token: string, range: string, filter?: Record<string, string>) => {
    const settings: Setting[] = await get(token, range, filter);
    return settings;
};


export const getSettingById = async (token: string, id: string): Promise<Setting> => {
    return await get1(token, id);
};

