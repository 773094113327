import {    
    OpenAPI,
    ScreenConfigService
} from '../openapi';


const { get1 } = ScreenConfigService;

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT as string;

export const getScreenConfig = async (token: string, siteId: string) => {
    const res: string = await get1(token, siteId);
    return res;
};

