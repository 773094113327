import React, { Component } from 'react'

const WAIT_INTERVAL = 2000
const ENTER_KEY = 13

class SearchInput extends Component {
    state = {
        
    }

    timer = null

    handleChange = e => {
        clearTimeout(this.timer)        

        if (e.target.type === "checkbox") {
            this.setState({ value: e.target.checked })
        }
        else {
            this.setState({ value: e.target.value })
        }

        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL)
    }

    handleKeyDown = e => {
        if (e.keyCode === ENTER_KEY) {
            clearTimeout(this.timer)
            this.triggerChange()
        }
    }

    triggerChange = () => {
        const { value } = this.state

        this.props.onChange(value)
    }

    render() {
        const { ...rest } = this.props

        return (
            <input
                type={rest.type}
                className={rest.className}
                placeholder={rest.placeholder}
                value={rest.value ?? this.state.value}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}                
                checked={rest.isChecked}
                style={{ width: rest.type !== 'checkbox' ? "100%" : undefined }}
            />
        )
    }
}

export default SearchInput