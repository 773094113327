import React from 'react'
import { CSVLink } from "react-csv";
import { Transaction } from '../../services/openapi';
import { ApiResult } from '../../services/openapi/core/ApiResult';
 

type CvsExportProps = {
    className: string,
    style: Record<string, string>,
    filename: string,
    separator: string,
    headers: {label: string, key:string }[],
    onGetData: () => Promise<ApiResult>
}

type CvsExportState = {
    collection: [],
    loading: boolean
}

export default class CsvExport extends React.Component<CvsExportProps, CvsExportState> {
    constructor(props: CvsExportProps) {
        super(props);

        this.state = {
            collection: [],
            loading: false
        };
    }

    getData = () => {
        if (!this.state.loading) {
            this.setState({
                loading: true
            });
            this.props.onGetData()
                .then((result) => {
                    this.setState({
                        collection: result.body,
                        loading: false
                    });
                    var btn = document.getElementById("cvsLink") as HTMLAnchorElement;
                    btn.click();
                }).catch(() => {
                    this.setState({
                        loading: false
                    });
                });
        }
    }    

    render() {
        const { loading } = this.state;
        return (
            <>
                <button
                    onClick={this.getData}
                    className={this.props.className}
                    style={this.props.style}
                >
                    {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : undefined}
                    &nbsp;Download
                </button>
                {this.state.collection && (
                    <CSVLink
                        id="cvsLink"
                        headers={this.props.headers}
                        data={this.state.collection}
                        filename={this.props.filename}
                        separator={this.props.separator}
                    />
                )}
            </>);
    }
}