import React from 'react'
import { CSVLink } from "react-csv";
import { Transaction } from '../../services/openapi';
import { ApiResult } from '../../services/openapi/core/ApiResult';
 

type JsonDownloadProps = {
    className?: string | null,
    style?: Record<string, string> | null,
    filename: string,    
    onGetData: () => Promise<string>
}

type JsonDownloadState = {
    loading: boolean
}

export default class JsonDownload extends React.Component<JsonDownloadProps, JsonDownloadState> {
    constructor(props: JsonDownloadProps) {
        super(props);

        this.state = {            
            loading: false
        };
    }

    getData = () => {
        if (!this.state.loading) {
            this.setState({
                loading: true
            });
            this.props.onGetData()
                .then((file) => {
                    this.setState({                        
                        loading: false
                    });

                    const link = document.createElement("a");
                    link.download = this.props.filename;
                    const blob = new Blob([file], { type: "application/json" });
                    link.href = URL.createObjectURL(blob);
                    link.click();
                    URL.revokeObjectURL(link.href);                    
                }).catch(() => {
                    this.setState({
                        loading: false
                    });
                });
        }
    }    

    render() {
        const { loading } = this.state;
        return (
            <>
                <button
                    type="button"
                    onClick={this.getData}
                    className={this.props.className ?? undefined}
                    style={this.props.style ?? undefined}
                >
                    {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : undefined}
                    &nbsp;Download
                </button>                
            </>);
    }
}