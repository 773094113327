
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Row, Col, Table, Form, FormCheck } from 'react-bootstrap';
import Card from '../../../components/Card';

import { VmcStatusItem } from '../../../features/vmcs';
import { getVmcs, updateVmc  } from '../../../services/api/vmc';
import { addLinkedItem, deleteAllLinkedItems, deleteLinkedItem, getLinkedItems } from '../../../services/api/linkedItem';
import { ApiError, LinkedItem, VmcStatusDto, Terminal } from '../../../services/openapi';

import { useKeycloak } from '@react-keycloak/web';

import SearchInput from '../../../components/SearchInput';
import ListPagination from '../../../components/ListPagination';
import RefreshPull from '../../../components/RefreshPull';
import { ItemLinker } from '../../../features/item-linker';
import { Link, useNavigate } from 'react-router-dom';
import { updateTerminal } from '../../../services/api/terminal';

const LinkVmcToTerminal = () => {
    const [error, setError] = useState<ApiError | null>();
    const [vmcs, setVmcs] = useState<VmcStatusDto[]>([]);
    const [formData, setFormData] = useState<string>("");
    const [searchData, setSearchData] = useState<Record<string, string>>({});
    const [locationCount, setLocationCount] = useState<string>("0");
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pagingString, setPagingString] = useState<string>("0-25");
    const [filterData, setFilterData] = useState<string>("");
    const [showLinked, setShowLinked] = useState<boolean | null>(null);
    const [modified, setModified] = useState<boolean>(false);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [terminal, setTerminal] = useState<any>(JSON.parse(localStorage.getItem('Terminal') ?? ""));
    const [linkedIds, setLinkedIds] = useState<number[]>([]);
    const [tableBodyHeight, setTableBodyHeight] = useState<number>(380);

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const navigate = useNavigate();

    const delay = async (ms: number) => {
        await new Promise(resolve => setTimeout(resolve, ms));
    }

    let timer: NodeJS.Timeout | null = null;

    const submitSearch = () => {
        formData === "" ? setFormData(" ") : setFormData("");
    }

    const navigateBack = () => {
        navigate('/dashboard/details/terminal');
    };

    const setTerminalData = (data: Terminal) => {
        localStorage.setItem('Terminal', JSON.stringify(data));
    }

    const handleFilter = (e: boolean): void => {
        setVmcs([]);
        setShowLinked(e);
    }    

    const handleSearchColumn1 = (e: string): void => {
        setVmcs([]);
        searchData["description"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleSearchColumn2 = (e: string): void => {
        setVmcs([]);
        searchData["locationDescription"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleRefreshButton = (): void => {
        setVmcs([]);
        filterData === "" ? setFilterData(" ") : setFilterData("");
        setShowLinked(null);
    }

    const handleChange = () => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(submitSearch, 2000);
    }

    const handlePaging = async (e: number) => {
        do {
            await delay(1000);
        } while (isRefreshing);

        setCurrentPage(e);

        const start = ((e + 1) * 25) - 25;
        const end = ((e + 1) * 25);
        const rangeString = `${start}-${end}`
        setPagingString(rangeString);

        document.querySelector('#refresh')?.classList.remove("d-none")
        setIsRefreshing(true);

        getLinkedItems(token, terminal!.id, "Terminal", "Vmc").then((linkedIds) => {
            if (linkedIds.length > 0) {
                setLinkedIds(linkedIds)
            }

            getVmcs(keycloak.token || '', rangeString, { search: formData, terminalId: filterData, linkTo: "Terminal", parentId: terminal!.id }, searchData).then((result) => {
                const allVmcs = result.body as VmcStatusDto[];
                if (showLinked === true) {
                    setVmcs(allVmcs.filter(x => linkedIds.indexOf(x.id) !== -1));
                } else if (showLinked === false) {
                    setVmcs(allVmcs.filter(x => linkedIds.indexOf(x.id) === -1));
                } else {
                    setVmcs(allVmcs);
                }

                var totalCount = result.header.split('/')[1];
                setLocationCount(totalCount);

                document.querySelector('#refresh')?.classList.add("d-none");

                setIsRefreshing(false);
            }).catch((error) => setError(error));
        });
    }

    const init = () => {
        handlePaging(0);
    };

    const onResize = () => {
        var e = document.querySelector(".vertical-scrollable") as HTMLElement;
        var h = window.innerHeight;
        e.style.height = (h - 284) + "px";
        e.style.maxHeight = (h - 280) + "px";
        setTableBodyHeight(h - 284);
    };

    useEffect(() => {
        window.addEventListener("resize", onResize);

        if (vmcs?.length == 0) {
            init();
            onResize();
        }

        return () => {
            window.removeEventListener("resize", onResize);
        };

    }, [formData, filterData, showLinked, linkedIds]);

    function remove(linkedIds: number[], id: number) {
        linkedIds.forEach((item, index) => {
            if (item === id) linkedIds.splice(index, 1);
        });
    }

    return (
        <>
            <Row className="">
                <Col className="">
                    <Card className="rounded">
                        <Card.Body className="">
                            <Row className="d-none d-md-block">
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row className="d-none d-md-block">
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row className="">
                                <Col sm="12" className="">
                                    <h4 className="mb-2 qvend-title">Terminal {terminal!.poi} - Automaat koppelen</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" xs="12" className="mt-2">
                                    <RefreshPull isRefreshing={isRefreshing} onSetPage={handlePaging} />
                                    <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                        <div>
                                            <Row className="qvend-table-font" style={{ background: "#2b2e83", color: "#FFFFFF", overflowY: "scroll" }}>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table table-column-first">Automaat</Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table">Locatie</Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table">Gekoppeld</Col>
                                            </Row>
                                        </div>
                                        <div className="vertical-scrollable" style={{ overscrollBehaviorY: 'contain' }}>
                                            <Row className={("qvend-table-font")} style={{ background: "#cfd5ea", color: "black" }}>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table search-in-row-first"><SearchInput className="form-control" type="search" placeholder="Search" onChange={handleSearchColumn1} /></Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table search-in-row-first"><SearchInput className="form-control" type="search" placeholder="Search" onChange={handleSearchColumn2} /></Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table" style={{ padding: "10px 16px" }}><SearchInput className="form-check-input" type="checkbox" onChange={handleFilter} isChecked={showLinked != null ? showLinked === true : undefined} /></Col>
                                            </Row>
                                            {vmcs.map((vmc: VmcStatusDto, index) => (
                                                <ItemLinker
                                                    key={vmc.id}
                                                    itemId={vmc.id ?? 0}
                                                    itemNameColumn1={vmc.description ?? ""}
                                                    itemNameColumn2={vmc.locationDescription ?? ""}
                                                    isChecked={linkedIds !== null && linkedIds.indexOf(vmc.id) !== -1}
                                                    isDisabled={linkedIds.length >= terminal.maxVmcs && linkedIds.indexOf(vmc.id) == -1 }
                                                    error={error === null ? undefined : error}
                                                    rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"}
                                                    handleChange={(e: ChangeEvent<HTMLInputElement>) => { setModified(true); setShowLinked(null); let t: Terminal = terminal; e.target.checked ? linkedIds?.push(vmc.id) : remove(linkedIds, vmc.id); setLinkedIds(linkedIds); e.target.checked ? addLinkedItem(token, { parentId: t.id ?? 0, parentType: 'Terminal', linkedEntityId: vmc.id ?? 0, linkedEntityType: "Vmc" }) : deleteLinkedItem(token, t.id ?? 0, 'Terminal', vmc.id ?? 0, 'Vmc') }}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <ListPagination
                                    articlesCount={locationCount}
                                    currentPage={currentPage}
                                    onSetPage={handlePaging}
                                    onRefresh={handleRefreshButton} />
                            </Row>
                            <Row style={{ paddingTop: "3px" }}>
                                <Col>
                                    <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px', backgroundColor: "red" }} onClick={() => { deleteAllLinkedItems(token, terminal.id ?? 0, 'Terminal', "Vmc").then(() => navigateBack()) }}>Annuleren</button>
                                </Col>
                                <Col className="text-end">
                                    <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={() => {
                                        //var vmcList = vmcs.filter(x => linkedIds.indexOf(x.id) !== -1);
                                        //if (vmcList) {
                                        //    vmcList.forEach(async (vmc) =>
                                        //        await updateVmc(token, vmc.id ?? 0, vmc));
                                        //    navigateBack();
                                        //}
                                        let t: Terminal = terminal; t.vmcIds = linkedIds; updateTerminal(token, t.id ?? 0, t).then(() => setTerminalData(t)).then(() => deleteAllLinkedItems(token, terminal.id ?? 0, 'Terminal', "Vmc").then(() => navigateBack()));
                                    }}>Opslaan</button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default LinkVmcToTerminal
