import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CustomToggle from './dropdowns'
import { Navbar, Container, Dropdown } from 'react-bootstrap';
import { useKeycloak } from "@react-keycloak/web";
import logo from '../assets/images/logo1.png';

import { getVmcCount } from '../services/api/vmc';

const Navb = () => {
    const [VmcCount, setVmcCount] = useState<number>(0);

    const { keycloak, initialized } = useKeycloak();    

    const navigate = useNavigate();

    const navigateToSettingsMenu = () => {
        navigate('/dashboard/menu/settings');
    };
    //const styleObj = {
    //    textAlign: 'right'
    //} as const

    useEffect(() => {        
        window.addEventListener("click", init);
        window.addEventListener("unload", handleCleanup);
        window.addEventListener("unload", clearSiteIdFilter);

        if (!initialized) {
            return;
        }

        const token = keycloak.token || '';

        if (token) {            
            getVmcCount(token).then((count) => setVmcCount(+count));
        }        

        return () => {            
            window.removeEventListener("click", init);
            window.removeEventListener("unload", handleCleanup);
            window.removeEventListener("unload", clearSiteIdFilter);
        };

    }, [keycloak, initialized]);

    function init(this: Window, event: MouseEvent) {
        if (event?.target) {
            const element = (event.target) as Element;
            if (element.classList.contains("menuItem")) {
                handleCleanup();                
            }            
        }
    }

    const closeHamburgerMenu = () => {
        var btn = document.getElementsByClassName("navbar-toggler") as HTMLCollectionOf<HTMLElement>;
        btn[0].click();
    }

    const handleCleanup = () => {
        //window.localStorage.clear();        
        window.localStorage.setItem("formData", "");
        window.localStorage.setItem("searchData", "");
        window.localStorage.setItem("filterData", "");
        window.localStorage.setItem("currentPage", "0");                  
    }

    const clearSiteIdFilter = () => {
        window.localStorage.setItem("siteFilter", "");
        window.localStorage.setItem("siteFilterQuery", "");
        window.localStorage.setItem("searchDataSites", "");
    }
    
    return (
        <Navbar expand="lg" className="nav iq-navbar">
            <Container fluid style={{ paddingLeft: "var(--bs-gutter-x, 0.5rem)" }} >
                <Navbar.Toggle aria-controls="navbarSupportedContent">
                    <span className="navbar-toggler-icon">
                        <span className="navbar-toggler-bar bar1 mt-2"></span>
                        <span className="navbar-toggler-bar bar2"></span>
                        <span className="navbar-toggler-bar bar3"></span>
                    </span>
                </Navbar.Toggle>
                <Navbar.Brand as="div" className="ms-auto navbar-list mb-lg-0">
                    <Link className="" to="/dashboard" onClick={() => { const item1 = window.localStorage.getItem("siteFilter"); const item2 = window.localStorage.getItem("siteFilterQuery"); const item3 = window.localStorage.getItem("searchDataSites"); window.localStorage.clear(); item1 && window.localStorage.setItem("siteFilter", item1); item2 && window.localStorage.setItem("siteFilterQuery", item2); item2 && item2.length > 2 && item3 && window.localStorage.setItem("searchDataSites", item3); }}>
                        <img src={logo} alt="logo" className="img-fluid p-0 flex-column" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Brand as="div" className="ms-auto navbar-list mb-lg-0">
                    
                </Navbar.Brand>
                <Navbar.Collapse className="col-md-2 ms-auto align-items-start" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto">
                        <li className="item-name">
                            <div className="nav-link d-sm-none h3">
                                Omzet
                            </div>
                            <div className="nav-link d-none d-sm-block">
                                Omzet
                            </div>
                        </li>
                        <li className="item-name">
                            <Link className={`${location.pathname === '/dashboard/lists/alerts' ? 'active' : ''} nav-link d-sm-none h3 menuItem`} to="/dashboard/lists/alerts" onClick={() => { closeHamburgerMenu(); handleCleanup(); }}>Alarmen</Link>
                            <Link className={`${location.pathname === '/dashboard/lists/alerts' ? 'active' : ''} nav-link d-none d-sm-block menuItem`} to="/dashboard/lists/alerts" onClick={() => { closeHamburgerMenu(); handleCleanup(); }}>Alarmen</Link>
                        </li>
                        <li className="item-name">
                            <Link className={`${location.pathname === '/dashboard/lists/transactions' ? 'active' : ''} nav-link d-sm-none h3 menuItem`} to="/dashboard/lists/transactions" onClick={() => { closeHamburgerMenu(); handleCleanup(); }}>Betalingen</Link>
                            <Link className={`${location.pathname === '/dashboard/lists/transactions' ? 'active' : ''} nav-link d-none d-sm-block menuItem`} to="/dashboard/lists/transactions" onClick={() => {closeHamburgerMenu(); handleCleanup(); }}>Betalingen</Link>
                        </li>
                        <Dropdown as="li" className="item-name d-none d-lg-block">
                            <Dropdown.Toggle as={CustomToggle} variant=" nav-link py-0 d-flex align-items-center" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">                                
                                <div className={`${location.pathname === '/dashboard/menu/settings' ? 'active' : ''} nav-link d-none d-sm-block menuItem`} onClick={() => {navigateToSettingsMenu();}}>Instellingen</div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <Link className={`${location.pathname === '/dashboard/lists/sites' ? 'active' : ''} nav-link d-none d-lg-block menuItem`} to="/dashboard/lists/sites" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Sites</Link>
                                <Link className={`${location.pathname === '/dashboard/lists/products' ? 'active' : ''} nav-link d-none d-lg-block menuItem`} to="/dashboard/lists/products" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Producten</Link>
                                <Link className={`${location.pathname === '/dashboard/lists/terminals' ? 'active' : ''} nav-link d-none d-lg-block menuItem`} to="/dashboard/lists/terminals" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Terminals</Link>                                
                                <Link className={`${location.pathname === '/dashboard/lists/locations' ? 'active' : ''} nav-link d-none d-lg-block menuItem`} to="/dashboard/lists/locations" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Locaties</Link>
                                <Link className={`${location.pathname === '/dashboard/lists/alertDefinitions' ? 'active' : ''} nav-link d-none d-lg-block menuItem`} to="/dashboard/lists/alertDefinitions" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Alarmen</Link>
                                {VmcCount > 0 ?
                                    <>                                        
                                        <Link className={`${location.pathname === '/dashboard/lists/vmcs' ? 'active' : ''} nav-link d-none d-lg-block`} to="/dashboard/lists/vmcs">&nbsp;&nbsp;Machines</Link>
                                    </>
                                    : null
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <li className="item-name d-sm-none h3">
                            <Link className={`${location.pathname === '/dashboard/menu/settings' ? 'active' : ''} nav-link d-sm-none h3 menuItem`} to="/dashboard/menu/settings" onClick={() => closeHamburgerMenu()}>Instellingen</Link>
                        </li>
                        <li className="item-name d-xs-none d-lg-none d-xl-none d-xxl-none d-sm-block">
                            <Link className={`${location.pathname === '/dashboard/menu/settings' ? 'active' : ''} nav-link d-none d-sm-block menuItem`} to="/dashboard/menu/settings" onClick={() => closeHamburgerMenu()}>Instellingen</Link>
                        </li>

                        <li className="item-name d-sm-none h3">
                            <Link className={`${location.pathname === '/dashboard/lists/sites' ? 'active' : ''} nav-link d-sm-none h3 menuItem`} to="/dashboard/lists/sites" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Sites</Link>
                        </li>
                        <li className="item-name d-xs-none d-lg-none d-xl-none d-xxl-none d-sm-block">
                            <Link className={`${location.pathname === '/dashboard/lists/sites' ? 'active' : ''} nav-link d-none d-sm-block menuItem`} to="/dashboard/lists/sites" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Sites</Link>
                        </li>

                        <li className="item-name d-sm-none h3">
                            <Link className={`${location.pathname === '/dashboard/lists/products' ? 'active' : ''} nav-link d-sm-none h3 menuItem`} to="/dashboard/lists/products" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Producten</Link>
                        </li>
                        <li className="item-name d-xs-none d-lg-none d-xl-none d-xxl-none d-sm-block">
                            <Link className={`${location.pathname === '/dashboard/lists/products' ? 'active' : ''} nav-link d-none d-sm-block menuItem`} to="/dashboard/lists/products" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Producten</Link>
                        </li>

                        <li className="item-name d-sm-none h3">
                            <Link className={`${location.pathname === '/dashboard/lists/terminals' ? 'active' : ''} nav-link d-sm-none h3 menuItem`} to="/dashboard/lists/terminals" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Terminals</Link>
                        </li>
                        <li className="item-name d-xs-none d-lg-none d-xl-none d-xxl-none d-sm-block">
                            <Link className={`${location.pathname === '/dashboard/lists/terminals' ? 'active' : ''} nav-link d-none d-sm-block menuItem`} to="/dashboard/lists/terminals" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Terminals</Link>
                        </li>
                        <li className="item-name d-sm-none h3">
                            <Link className={`${location.pathname === '/dashboard/lists/locations' ? 'active' : ''} nav-link d-sm-none h3 menuItem`} to="/dashboard/lists/locations" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Locaties</Link>
                        </li>
                        <li className="item-name d-xs-none d-lg-none d-xl-none d-xxl-none d-sm-block">
                            <Link className={`${location.pathname === '/dashboard/lists/locations' ? 'active' : ''} nav-link d-none d-sm-block menuItem`} to="/dashboard/lists/locations" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Locaties</Link>
                        </li>
                        <li className="item-name d-sm-none h3">
                            <Link className={`${location.pathname === '/dashboard/lists/alertDefinitions' ? 'active' : ''} nav-link d-sm-none h3 menuItem`} to="/dashboard/lists/alertDefinitions" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Alarmen</Link>
                        </li>
                        <li className="item-name d-xs-none d-lg-none d-xl-none d-xxl-none d-sm-block">
                            <Link className={`${location.pathname === '/dashboard/lists/alertDefinitions' ? 'active' : ''} nav-link d-none d-sm-block menuItem`} to="/dashboard/lists/alertDefinitions" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Alarmen</Link>
                        </li>
                        {VmcCount > 0 ?
                            <>
                            <li className="item-name d-sm-none h3">
                                    <Link className={`${location.pathname === '/dashboard/lists/vmcs' ? 'active' : ''} nav-link d-sm-none h3 menuItem`} to="/dashboard/lists/vmcs" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Machines</Link>                                
                            </li>
                            <li className="item-name d-xs-none d-lg-none d-xl-none d-xxl-none d-sm-block">
                                    <Link className={`${location.pathname === '/dashboard/lists/vmcs' ? 'active' : ''} nav-link d-none d-sm-block menuItem`} to="/dashboard/lists/vmcs" onClick={() => closeHamburgerMenu()}>&nbsp;&nbsp;Machines</Link>                                
                            </li>
                            </>
                            : null
                        }
                        <div className="item-name" style={{ cursor: "pointer" }} onClick={() => !keycloak.authenticated && keycloak.login() || !!keycloak.authenticated && keycloak.logout()}>                            
                            <li className="nav-link d-sm-none h3">Logout</li>
                            <li className="nav-link d-none d-sm-block">Logout</li>
                        </div>
                    </ul>
                </Navbar.Collapse>                
            </Container>
        </Navbar>


        //<div>
        //    <div className="top-0 w-full flex flex-wrap" style={styleObj}>
        //        <section className="x-auto">
        //            <nav className="flex justify-between bg-gray-200 text-blue-800 w-screen">
        //                <div className="px-5 xl:px-12 py-6 flex w-full items-center">                            
        //                    <div className="hidden xl:flex items-center space-x-5">
        //                        <div className="hover:text-gray-200">
        //                            {!keycloak.authenticated && (
        //                                <button
        //                                    type="button"
        //                                    className="text-blue-800"
        //                                    onClick={() => keycloak.login()}
        //                                >
        //                                    Login
        //                                </button>
        //                            )}

        //                            {!!keycloak.authenticated && (
        //                                <button
        //                                    type="button"
        //                                    className="text-blue-800"
        //                                    onClick={() => keycloak.logout()}
        //                                >
        //                                    Logout ({keycloak.tokenParsed === undefined ? '' : keycloak.tokenParsed.preferred_username})
        //                                </button>
        //                            )}
        //                        </div>
        //                    </div>
        //                </div>
        //            </nav>
        //        </section>
        //    </div>
        //</div>
    );
};


export default Navb;

