
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Row, Col, Table, Form, FormCheck } from 'react-bootstrap';
import Card from '../../../components/Card';

import { getVmcTypes } from '../../../services/api/vmcType';
import { addLinkedItem, deleteAllLinkedItems, deleteLinkedItem, getLinkedItems } from '../../../services/api/linkedItem';
import { ApiError, LinkedItem, VmcType, VmcStatusDto } from '../../../services/openapi';

import { useKeycloak } from '@react-keycloak/web';

import SearchInput from '../../../components/SearchInput';
import ListPagination from '../../../components/ListPagination';
import RefreshPull from '../../../components/RefreshPull';
import { ItemLinker } from '../../../features/item-linker';
import { Link, useNavigate } from 'react-router-dom';
import { updateVmc } from '../../../services/api/vmc';

const LinkVmcTypeToVmc = () => {
    const [error, setError] = useState<ApiError | null>();
    const [vmcTypes, setVmcTypes] = useState<VmcType[]>([]);
    const [formData, setFormData] = useState<string>("");
    const [searchData, setSearchData] = useState<Record<string, string>>({});
    const [locationCount, setLocationCount] = useState<string>("0");
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pagingString, setPagingString] = useState<string>("0-25");
    const [filterData, setFilterData] = useState<string>("");
    const [showLinked, setShowLinked] = useState<boolean | null>(null);
    const [modified, setModified] = useState<boolean>(false);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [vmc, setVmc] = useState<any>(JSON.parse(localStorage.getItem('Vmc') ?? ""));    
    const [linkedId, setLinkedId] = useState<number | null>(null);    
    const [tableBodyHeight, setTableBodyHeight] = useState<number>(380);

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const navigate = useNavigate();

    const delay = async (ms: number) => {
        await new Promise(resolve => setTimeout(resolve, ms));
    }

    let timer: NodeJS.Timeout | null = null;

    const submitSearch = () => {
        formData === "" ? setFormData(" ") : setFormData("");
    }

    const navigateBack = () => {
        navigate('/dashboard/details/vmc');
    };

    const setVmcData = (data: VmcStatusDto) => {
        localStorage.setItem('Vmc', JSON.stringify(data));
    }

    const handleFilter = (e: boolean): void => {        
        setVmcTypes([]);                    
        setShowLinked(e);                
    }

    const handleSearchColumn1 = (e: string): void => {
        setVmcTypes([]);
        searchData["brand"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleSearchColumn2 = (e: string): void => {
        setVmcTypes([]);
        searchData["description"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleRefreshButton = (): void => {
        setVmcTypes([]);
        filterData === "" ? setFilterData(" ") : setFilterData("");
        setShowLinked(null);               
    }

    const handleChange = () => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(submitSearch, 2000);
    }

    const handlePaging = async (e: number) => {
        do {
            await delay(1000);
        } while (isRefreshing);

        setCurrentPage(e);

        const start = ((e + 1) * 25) - 25;
        const end = ((e + 1) * 25);
        const rangeString = `${start}-${end}`
        setPagingString(rangeString);

        document.querySelector('#refresh')?.classList.remove("d-none")
        setIsRefreshing(true);

        getLinkedItems(token, vmc!.id, "Vmc", "VmcType").then((linkedIds) => {
            if (linkedIds.length > 0) {
                setLinkedId(linkedIds[0])
            }
                            
            getVmcTypes(keycloak.token || '', rangeString, { search: formData, terminalId: filterData, linkTo: "Vmc", parentId: vmc!.id }, searchData).then((result) => {
                const allTypes = result.body as VmcType[];
                if (showLinked === true) {
                    setVmcTypes(allTypes.filter(x => x.id === linkedId));
                } else if (showLinked === false) {
                    setVmcTypes(allTypes.filter(x => x.id !== linkedId));
                } else {
                    setVmcTypes(allTypes);
                }

                var totalCount = result.header.split('/')[1];
                setLocationCount(totalCount);

                document.querySelector('#refresh')?.classList.add("d-none");

                setIsRefreshing(false);
            }).catch((error) => setError(error));
        });                
    }

    const init = () => {
        handlePaging(0);        
    };            

    const onResize = () => {
        var e = document.querySelector(".vertical-scrollable") as HTMLElement;
        var h = window.innerHeight;
        e.style.height = (h - 284) + "px";
        e.style.maxHeight = (h - 280) + "px";
        setTableBodyHeight(h - 284);
    };

    useEffect(() => {
        window.addEventListener("resize", onResize);

        if (vmcTypes?.length == 0) {
            init();            
            onResize();
        }

        return () => {
            window.removeEventListener("resize", onResize);
        };

    }, [formData, filterData, showLinked, linkedId]);

    return (
        <>
            <Row className="">                  
                <Col className="">
                    <Card className="rounded">                        
                        <Card.Body className="">
                            <Row className="d-none d-md-block">
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row className="d-none d-md-block">
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row className="">
                                <Col sm="12" className="">
                                    <h4 className="mb-2 qvend-title">Automaat {vmc!.identifier} - Type koppelen</h4>                                    
                                </Col>
                            </Row>                            
                            <Row>
                                <Col sm="12" xs="12" className="mt-2">
                                    <RefreshPull isRefreshing={isRefreshing} onSetPage={handlePaging} />
                                    <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                        <div>
                                            <Row className="qvend-table-font" style={{ background: "#2b2e83", color: "#FFFFFF", overflowY: "scroll" }}>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table table-column-first">Merk</Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table table-column-first">Type</Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table">Gekoppeld</Col>                                                
                                            </Row>                                            
                                        </div>
                                        <div className="vertical-scrollable" style={{ overscrollBehaviorY: 'contain' }}>
                                            <Row className={("qvend-table-font")} style={{ background: "#cfd5ea", color: "black" }}>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table search-in-row-first"><SearchInput className="form-control" type="search" placeholder="Search" onChange={handleSearchColumn1} /></Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table search-in-row-first"><SearchInput className="form-control" type="search" placeholder="Search" onChange={handleSearchColumn2} /></Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table" style={{ padding: "10px 16px" }}><SearchInput className="form-check-input" type="checkbox" onChange={handleFilter} isChecked={showLinked != null ? showLinked === true : undefined} /></Col>
                                                </Row>
                                            {vmcTypes.map((type: VmcType, index) => (
                                                type.hidden ? "" :
                                                    <ItemLinker
                                                        key={type.id}
                                                        itemId={type.id ?? 0}
                                                        itemNameColumn1={type.brand}
                                                        itemNameColumn2={type.description}
                                                        isChecked={linkedId !== null && type.id === linkedId }
                                                        isDisabled={linkedId !== null && type.id !== linkedId} 
                                                        error={error === null ? undefined : error}
                                                        rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"}
                                                        handleChange={(e: ChangeEvent<HTMLInputElement>) => { setModified(true); setShowLinked(null); let v: VmcStatusDto = vmc; setLinkedId(e.target.checked ? type.id ?? null : null); v.type = e.target.checked ? type.description : ""; setVmc(v);  e.target.checked ? addLinkedItem(token, { parentId: v.id ?? 0, parentType: 'Vmc', linkedEntityId: type.id ?? 0, linkedEntityType: "VmcType" }) : deleteLinkedItem(token, v.id ?? 0, 'Vmc', type.id ?? 0, 'VmcType') }}
                                                    />
                                                ))}                                            
                                        </div>                                        
                                    </div>
                                </Col>                              
                            </Row>
                            <Row>
                                <ListPagination
                                    articlesCount={locationCount}
                                    currentPage={currentPage}
                                    onSetPage={handlePaging}
                                    onRefresh={handleRefreshButton} />
                            </Row>
                            <Row style={{ paddingTop: "3px" }}>
                                <Col>
                                    <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px', backgroundColor: "red" }} onClick={() => { deleteAllLinkedItems(token, vmc.id ?? 0, 'Vmc', "VmcType").then(() => navigateBack()) }}>Annuleren</button>                                    
                                </Col>
                                <Col className="text-end">
                                    <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={() => { let v: VmcStatusDto = vmc; v.vmcTypeId = linkedId ?? 0; updateVmc(token, v.id ?? 0, v).then(() => setVmcData(v)).then(() => deleteAllLinkedItems(token, vmc.id ?? 0, 'Vmc', "VmcType").then(() => navigateBack())) }}>Opslaan</button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>                                    
            </Row>
        </>
    )
}

export default LinkVmcTypeToVmc
