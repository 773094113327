import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap'

import { getSettings } from '../../../services/api/setting'

import { useKeycloak } from '@react-keycloak/web'


const Settings = () => {
    const { keycloak } = useKeycloak();
    const [isRefreshing, setIsRefreshing] = useState(false);

    const init = () => {
        if (!isRefreshing) {
            setIsRefreshing(true);
            const token = keycloak.token || '';
            getSettings(token).then((settings) => {
                settings.map((setting) => { console.log("setting:" + setting.name); sessionStorage.setItem(setting.name, setting.value); })
            });
        }

    }

    useEffect(() => {
        //init();        
    })

    return (
        <>
            <Row className="d-none d-md-block d-lg-block">
                <Col>&nbsp;</Col>
            </Row>
            <Row className="d-none d-md-block d-lg-block">
                <Col>&nbsp;</Col>
            </Row>
            <Row className="d-none d-md-block d-lg-block">
                <Col>&nbsp;</Col>
            </Row>
            <Row>
                <Col>&nbsp;</Col>
            </Row>
            <Row>
                <Col>
                    <Row className="row-cols-1">
                        
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="container">
                            <ul className="image-gallery">
                                <li>
                                    <Link className="" to="/dashboard/lists/terminals">
                                        <img src="./Icons/Iconen_Q-Vend_VALINA_Groep3.svg" alt="" heigth="125" width="125" />
                                        <span className="image-icon-text">Terminals</span>
                                    </Link>
                                </li>
                                <li>
                                    <img src="./Icons/Iconen_Q-Vend_Persoon_Groep5.svg" alt="" heigth="125" width="125" />
                                    <span className="image-icon-text">Gebruikers</span>
                                </li>
                                <li>
                                    <Link className="" to="/dashboard/lists/alertDefinitions">
                                        <img src="./Icons/Iconen_Q-Vend_Meldingen_Driehoek.svg" alt="" heigth="125" width="125" />
                                        <span className="image-icon-text">Alarmen</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="" to="/dashboard/lists/products">
                                        <img src="./Icons/Iconen_Q-Vend_Producten_Groep3.svg" alt="" heigth="125" width="125" />
                                        <span className="image-icon-text">Producten</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="" to="/dashboard/lists/vmcs">
                                        <img src="./Icons/Iconen_Q-Vend_Segment_VendingAutomaat.svg" alt="" heigth="125" width="125" />
                                        <span className="image-icon-text">Vending Machines</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="" to="/dashboard/lists/locations">
                                        <img src="./Icons/Iconen_Q-Vend_Contact_Kantoor.svg" alt="" heigth="125" width="125" />
                                        <span className="image-icon-text">Locaties</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="" to="/dashboard/lists/sites">
                                            <img src="./Icons/Iconen_Q-Vend_Koppeling_Hubject.svg" alt="" heigth="125" width="125" />
                                        <span className="image-icon-text">Sites</span>
                                    </Link>
                                </li>
                            </ul>
                            </div>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="" style={{ textAlign: "center" }}>
                            <img src="./Icons/Iconen_Q-Vend_Algemeen_Golfje.svg" width="300" />
                        </Col>
                    </Row>
                </Col>

            </Row>
        </>
    )
}

export default Settings
