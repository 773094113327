import React, { ChangeEvent, ChangeEventHandler, FocusEventHandler, MouseEventHandler, useRef } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

type DetailProps = {
    propertyName: string;
    defaultValue: any;    
    rowColor: string;
    editType: string;
    className?: string;
    pattern?: string;
    showInputEle: boolean;
    handleDoubleClick: MouseEventHandler;
    handleChange: ChangeEventHandler<HTMLElement>;    

    possibilities?: string[] | null;
    //handleBlur: FocusEventHandler<HTMLInputElement>;
};

const DetailItem = ({ propertyName, defaultValue, rowColor, className, editType, pattern, showInputEle, handleDoubleClick, handleChange, possibilities = [] }: DetailProps) => {

    const isRequired = (pattern : string | undefined) => {
        var ranges = pattern?.split("]{") ?? [];

        if (ranges.length > 0) {
            var range = ranges.pop() ?? "";
            var minValue = Number(range.split(",")?.at(0) ?? 0);
            if (minValue > 0) {
                return true;
            }
        }

        return false;
    } 


    return (
        <>
            <Row className={("qvend-table-font " + (className ?? ""))} style={{ background: rowColor, color: "black" }}>
                <Col xl={6} lg={6} md={6} sm={6} xs={6} className="qvend-table table-column-first q-vend-detail-table-col">{propertyName}</Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={6} className="qvend-table q-vend-detail-table-col" onDoubleClick={(e) => { handleDoubleClick(e); }}>{
                    showInputEle ? editType === "string" ? (
                        <span><Form.Control
                            type="text"
                            defaultValue={defaultValue}
                            onChange={(e) => { handleChange(e); }}
                            size="sm"                            
                            pattern={pattern}
                            required={isRequired(pattern)}
                            onBlur={(e) => e.target.reportValidity()}
                        /></span>) : editType === "checkbox" ? (<span>
                            <Form.Check
                                defaultChecked={defaultValue.toString().toLowerCase() === "true" ? true : false}
                                onChange={(e) => { console.log("e:" + e.target.checked); e.target.value = e.target.checked ? "True" : "False"; handleChange(e); }}
                                /></span>)
                            : editType == "list" && possibilities ? <Form.Select className="form-control" style={{ padding: "0.3rem 3rem 0.3rem 1rem" }} defaultValue={defaultValue} onChange={(e) => {
                                console.log("e:" + e.target.value); handleChange(e); }}> 
                                {possibilities.map((v: string) => (<option>{v}</option>))}
                            </Form.Select> : (<span>{defaultValue}</span>)

                    : editType === "checkbox" ?
                            (<span><Form.Check
                                defaultChecked={defaultValue.toString().toLowerCase() === "true" ? true : false}
                                onChange={(e) => { console.log("e:" + e.target.checked); e.target.value = e.target.checked ? "True" : "False"; handleChange(e); }}
                            disabled={true} /></span>) :
                        (<span>{defaultValue}</span>)
                }
                </Col>
            </Row>
        </>
    );
};

DetailItem.defaultProps = {
    possibilities: []
};

export default DetailItem;
