import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const NotImplementedWarningModal = ({showModal, handleClose }) => {    
    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Waarschuwing</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sorry, deze functie is niet beschikbaar</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>Sluiten</Button>
            </Modal.Footer>
        </Modal>
    )
}