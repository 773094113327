import React, { useState, useEffect, ChangeEvent, MouseEventHandler } from 'react';
import { Row, Col, Alert } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { DetailItem } from '../../../../features/details';
import { updateProduct } from '../../../../services/api/productVmcLink';
import { getTerminalMdbSettingById } from '../../../../services/api/terminalMdbSettings'
import { ApiError, ProductVmcLink, VmcStatusDto } from '../../../../services/openapi';
import { useKeycloak } from '@react-keycloak/web'
import { PropertyInfo } from '../../../../services/openapi/models/PropertyInfo';
import { Link, useNavigate } from "react-router-dom";
import { NotImplementedWarningModal } from '../../../../components/NotImplementedWarningModal';
import { addLinkedItem, addLinkedItems, deleteAllLinkedItems } from '../../../../services/api/linkedItem';

import { ApiResult } from '../../../../services/openapi/core/ApiResult';
import { getVmcs } from '../../../../services/api/vmc';

const ProductVmcLinkDetails = () => {
    const [productVmcLink, setProductVmcLink] = useState<any>();
    const [showInputEle, setShowInputEle] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string[]>([]);
    const [firstTime, setFirstTime] = useState(true);

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const navigate = useNavigate();

    const navigateBack = () => {
        navigate('/dashboard/details/vmc/vmc-linked-products');
    };

    const setProductVmcLinkData = (data: ProductVmcLink) => {
        localStorage.setItem('ProductVmcLink', JSON.stringify(data));
    }

    const toggleInputElements = (show: boolean) => {
        setShowInputEle(show);
        if (show) {            
            document.querySelector('.errorMsg')?.classList.add("d-none");
            setErrorMsg([]);
        }
    }

    useEffect(() => {
        if (productVmcLink == null || productVmcLink == undefined) {
            const json = localStorage.getItem('ProductVmcLink') ?? "";
            const item = JSON.parse(json);
            setProductVmcLink(item);

            if (firstTime && item != undefined && (item as ProductVmcLink).locationSelection == '') {
                toggleInputElements(true);
                setFirstTime(false);
            }
        }        
    }, []);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        if (e.currentTarget.checkValidity()) {
            let link: ProductVmcLink = productVmcLink;
            setProductVmcLinkData(link);
            updateProduct(token, link.productId, link).catch((error: ApiError) => {                
                document.querySelector('.errorMsg')?.classList.remove("d-none");
                {/* k0 contains field name: e.g. LocationSelection, k1 contains array of error messages*/ }
                Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg);}) });
        });
            toggleInputElements(!showInputEle);
        }
    }

    return (
        <>
            <div className="">
                <NotImplementedWarningModal
                    showModal={showModal}
                    handleClose={() => {
                        setShowModal(false);
                    }}
                />
            </div>
            <Row className="">
                <Col className="">
                    <Card className="rounded">
                        <Card.Body className="">
                            <form onSubmit={e => handleSubmit(e)}>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>                                
                                <Row className="">
                                    <Col sm="12" className="">
                                        <h4 className="mb-2 qvend-title">Automaatdetails - Product koppeldetails</h4>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" xs="12" className="mt-2">
                                        <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                            <div>
                                                {productVmcLink && Object.entries<PropertyInfo>(productVmcLink).filter(([key, value]) => productVmcLink["propertyNames"][key] !== undefined).sort((a, b) => productVmcLink["propertyNames"][a[0]].sortOrderLevel - productVmcLink["propertyNames"][b[0]].sortOrderLevel).map(([key, value], index) => (
                                                    <DetailItem key={index} propertyName={productVmcLink["propertyNames"][key].name} defaultValue={value ?? ""} rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"} editType={productVmcLink["propertyNames"][key].editType} pattern={productVmcLink["propertyNames"][key].valPattern}
                                                        handleDoubleClick={() => toggleInputElements(true)}
                                                        handleChange={(e: ChangeEvent<HTMLInputElement>) => { productVmcLink[key] = (e.target.value); setProductVmcLink(productVmcLink); }} showInputEle={showInputEle}></DetailItem>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="d-none errorMsg">
                                    <Col>
                                        {
                                            errorMsg.map((item, index) => {
                                                return <Alert key={index} variant="warning" className="alert" role="alert">
                                                          <span>{item}</span>
                                                       </Alert>;
                                            })
                                        }                                        
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "12px" }}>
                                    <Col>                                        
                                    </Col>
                                    <Col className="text-end">
                                        {productVmcLink != undefined && (productVmcLink as ProductVmcLink).locationSelection ?
                                        (<button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={() => {
                                                setProductVmcLinkData(productVmcLink);
                                                deleteAllLinkedItems(token, productVmcLink.vmcId ?? 0, 'Vmc', "Product").then(() => {
                                                if (productVmcLink.productId) {
                                                    addLinkedItem(token, { parentId: productVmcLink.vmcId ?? 0, parentType: 'Vmc', linkedEntityId: productVmcLink.productId, linkedEntityType: "Product" }).then(() => {
                                                        navigate('/dashboard/items-linkers/product-to-vmc');
                                                    });
                                                }
                                                else {
                                                    navigate('/dashboard/items-linkers/product-to-vmc');
                                                }
                                            })
                                            }}>Product</button>) : undefined}
                                    </Col>
                                </Row>                                
                                <Row style={{ paddingTop: "3px" }}>
                                    <Col>
                                        <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px', backgroundColor: "red" }} onClick={() => navigateBack()}>Terug</button>                                        
                                    </Col>
                                    <Col className="text-end">
                                        {showInputEle ?
                                            (<button type="submit" className="btn btn-fixed-width" style={{ fontSize: '11px' }}>Opslaan</button>) :
                                            (<button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={(e) => { e.preventDefault(); toggleInputElements(true); }}>Wijzigen</button>)
                                        }
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ProductVmcLinkDetails;