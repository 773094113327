import React from 'react';
import { AlertDefinition } from '../../services/openapi'
import { ApiError } from '../../services/openapi';
import { Col, Row } from 'react-bootstrap';

type AlertDefinitionProps = {    
    error?: ApiError;
    alertDefinition: AlertDefinition;
    rowColor: string;
    className?: string;
};

const AlertDefinitionItem = ({ alertDefinition, rowColor, className }: AlertDefinitionProps) => {
    return (
        <>
            <Row className={("qvend-table-font " + (className ?? ""))} style={{ background: rowColor, color: "black" }}>
                <Col xl={6} lg={6} md={6} sm={5} xs={5} className="qvend-table table-column-first">{alertDefinition.alertTypeDescription}</Col>
                <Col xl={2} lg={2} md={2} sm={0} xs={0} className="qvend-table d-none d-md-block">{alertDefinition.siteId}</Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={2} className="qvend-table">{alertDefinition.sendMail === "True" ? "ja" : "nee"}</Col>
                <Col xl={2} lg={2} md={2} sm={5} xs={5} className="qvend-table">{alertDefinition.modificationDateTime}</Col>
            </Row>
        </>
    );
};

export default AlertDefinitionItem;
